import classNames from "classnames";
import format from "date-fns/format";
import { inject, observer } from "mobx-react";
import { Component, MouseEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { AddAction } from "nvent-web/components/Actions/AddAction";
import { EditAction } from "nvent-web/components/Actions/EditAction";
import { GetBOMAction } from "nvent-web/components/Actions/GetBOMAction";
import { GetPDFAction } from "nvent-web/components/Actions/GetPDFAction";
import { GetQuickReportAction } from "nvent-web/components/Actions/GetQuickReportAction";
import Card from "nvent-web/components/Card";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { formatReferenceNumber } from "nvent-web/utils/formatReferenceNumber";

import style from "./MobileProjectDetailsCard.module.scss";

type MobileProjectDetailsCardProps = {
  project: DetailedProject;
  reportDownloading: boolean;
  billOfMaterialsDownloading: boolean;
  onEdit: (id: number) => void;
  onGetBOM: (id: number) => void;
  onGetReport: MouseEventHandler<HTMLButtonElement>;
  onAddLevel: () => void;
};

type MobileProjectDetailsCardInnerProps = MobileProjectDetailsCardProps & {
  projects: ProjectsStore;
};
class MobileProjectDetailsCardInner extends Component<MobileProjectDetailsCardInnerProps> {
  render() {
    const { onGetBOM, onGetReport, onAddLevel, onEdit, reportDownloading, billOfMaterialsDownloading } = this.props;
    const { id, name, clientName, clientPhoneNumber, createdAt, progress, address, referenceNumber, finished } =
      this.props.project;
    const { areDetailsExpanded, toggleDetailsExpanded } = this.props.projects.getLocalState(id);

    const reportProps = {
      onClick: onGetReport,
      loading: reportDownloading,
    };

    const actions = [
      <EditAction onClick={() => onEdit(id)} />,
      finished ? <GetPDFAction {...reportProps} /> : <GetQuickReportAction {...reportProps} />,
      <GetBOMAction onClick={() => onGetBOM(id)} loading={billOfMaterialsDownloading} />,
      !finished && <AddAction onClick={onAddLevel} />,
    ].filter(Boolean);

    return (
      <Card
        to={`/projects/${id}`}
        title={name}
        actions={actions}
        toggleExpanded={toggleDetailsExpanded}
        isExpanded={areDetailsExpanded}
        classNames={{ card: style.card }}
        isFinished={finished}
      >
        <div className={style.details}>
          <div className={style.row}>
            <p className={style.referenceNumberLabel}>
              <FormattedMessage id={"projects.details.referenceNumber"} />
            </p>
            <span className={style.referenceNumberValue}>{formatReferenceNumber(referenceNumber)}</span>
          </div>
          <div className={style.row}>
            <p className={style.startLabel}>
              <FormattedMessage id={"projects.details.startProject"} />
            </p>
            <span className={style.startValue}>{format(createdAt, "dd-MM-yyyy")}</span>
          </div>
          <hr />
          <div className={classNames(style.row, style.clientHeaderRow)}>
            <p className={style.clientHeader}>
              <FormattedMessage id={"projects.details.projectInfo"} />
            </p>
          </div>
          <div className={classNames(style.row, style.clientNameRow)}>
            <p className={style.clientName}>{clientName}</p>
            <a href={`tel:${clientPhoneNumber}`} className={style.clientPhoneNumber}>
              {clientPhoneNumber}
            </a>
          </div>

          <div className={classNames(style.row, style.addressRow)}>
            <p className={style.clientAddress}>{address.streetAddress}</p>
          </div>
          {!finished && (
            <div className={classNames(style.row, style.progressRow)}>
              <ProgressFull progress={progress} />
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export const MobileProjectDetailsCard = compose<MobileProjectDetailsCardInnerProps, MobileProjectDetailsCardProps>(
  inject("projects"),
  observer
)(MobileProjectDetailsCardInner);
