import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";

import { ReactComponent as CheckIcon } from "nvent-web/assets/svg/check-light.svg";
import { gridBreakpoints } from "nvent-web/lib/constants";

import style from "./CommissionedLabel.module.scss";

export const CommissionedLabel = () => {
  return (
    <MediaQuery minWidth={gridBreakpoints.lg}>
      {(isDesktop) => {
        return isDesktop ? (
          <div className={style.label}>
            <FormattedMessage id="label.commissioned" />
          </div>
        ) : (
          <div className={style.iconWrapper}>
            <CheckIcon className={style.icon} />
          </div>
        );
      }}
    </MediaQuery>
  );
};
