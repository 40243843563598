import { AncestorLevel } from "nvent-web/types/DetailedRoom";

export const getAncestryString = ({
  projectName,
  levels = [],
  roomName,
}: {
  projectName?: string;
  levels?: AncestorLevel[];
  roomName?: string;
}) => {
  const levelsString = getAncestryLevelsString(levels);

  return [projectName, levelsString, roomName].filter(Boolean).join(" / ");
};

export const getAncestryLevelsString = (levels: AncestorLevel[]) => {
  return levels
    .map((level) => {
      return {
        name: level.name,
        index: level.ancestry.split("/").filter(Boolean).length,
      };
    })
    .sort((a, b) => a.index - b.index)
    .map((level) => level.name)
    .join(" / ");
};
