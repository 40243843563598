import { History } from "history";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import { LoadingSection } from "nvent-web/components/LoadingSection";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { BuildingType } from "nvent-web/types/BuildingType";
import { Room } from "nvent-web/types/Room";
import { SelectionGuideFormValues } from "nvent-web/types/SelectionGuideFormValues";

import { getAncestryString } from "../../utils/getAncestryString";
import StepHeading from "../StepHeading/StepHeading";

import RoomDetailsStepForm from "./components/RoomDetailsStepForm/RoomDetailsStepForm";

interface RoomDetailsStepParams {
  projectId: string;
  roomId: string;
}

interface RoomDetailsStepInnerProps extends RouteComponentProps<RoomDetailsStepParams>, InjectedIntlProps {
  rooms: RoomsStore;
  projects: ProjectsStore;
  history: History;
}

class RoomDetailsStepInner extends Component<RoomDetailsStepInnerProps> {
  @computed
  get projectId(): number {
    return parseInt(this.props.match.params.projectId, 10);
  }

  @computed
  get roomId(): number {
    return parseInt(this.props.match.params.roomId, 10);
  }

  @computed
  get buildingType(): BuildingType | null {
    return (this.props.projects.projectDetails && this.props.projects.projectDetails.buildingType) || null;
  }

  componentDidMount() {
    this.props.rooms.getRoom(this.projectId, this.roomId);
    this.props.projects.getProject(this.projectId);
  }

  render() {
    const { roomDetails, areRoomDetailsLoading, isRoomCreating } = this.props.rooms;
    const { projectDetails } = this.props.projects;

    const ancestryString = getAncestryString({
      projectName: projectDetails?.name,
      levels: roomDetails?.levels,
      roomName: roomDetails?.name,
    });

    if (areRoomDetailsLoading) {
      return <LoadingSection />;
    }

    return (
      <>
        <StepHeading step={2} ancestry={ancestryString} />
        {roomDetails && this.renderForm(roomDetails, isRoomCreating)}
      </>
    );
  }

  private initialValues(room: Room): SelectionGuideFormValues {
    return {
      floorConstruction: room.floorConstruction || null,
      floorFinish: room.floorFinish || null,
      area: room.area,
      installableArea: room.installableArea,
      quickHeating: room.quickHeating || false,
      areaType: room.areaType,
    };
  }

  private renderForm(values: Room, isRoomCreating: boolean) {
    return (
      <RoomDetailsStepForm
        isSubmitting={isRoomCreating}
        onCancel={this.handleCancel}
        initialValues={this.initialValues(values)}
        handleSubmit={this.handleSubmit}
        buildingType={this.buildingType}
      />
    );
  }

  private handleSubmit = async (formValues: SelectionGuideFormValues) => {
    const { rooms, history } = this.props;

    await rooms.updateRoom(formValues, this.projectId, this.roomId);
    history.push(`/projects/${this.projectId}/rooms/${this.roomId}/selection-guide/recommendations`);
  };

  private handleCancel = () => {
    this.props.history.push(`/projects/${this.projectId}/rooms/${this.roomId}`);
  };
}

const RoomDetailsStep = compose<RoomDetailsStepInnerProps, Record<string, unknown>>(
  inject("rooms", "projects"),
  injectIntl,
  withRouter,
  observer
)(RoomDetailsStepInner);

export default RoomDetailsStep;
