import classNames from "classnames";
import { History } from "history";
import groupBy from "lodash-es/groupBy";
import keyBy from "lodash-es/keyBy";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import "react-image-lightbox/style.css";
import prettyBytes from "pretty-bytes";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import InstallationPhotoExampleSrc from "nvent-web/assets/png/installation-photo-example.png";
import StickerPhotoExampleSrc from "nvent-web/assets/png/sticker-photo-example.png";
import { SecondaryRedButton } from "nvent-web/components/Button";
import FileButton, { defaultMaxSize, ValidatedFile } from "nvent-web/components/Button/FileButton";
import FormattedMeasurement from "nvent-web/components/FormattedMeasurement/FormattedMeasurement";
import { HelpTip } from "nvent-web/components/HelpTip";
import ItemList from "nvent-web/components/ItemList";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import Modal from "nvent-web/components/Modal";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import TranslatedString from "nvent-web/components/TranslatedString/TranslatedString";
import Api from "nvent-web/services/Api";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { PhotosStore } from "nvent-web/stores/Photos";
import { ProductsStore } from "nvent-web/stores/Products";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { DetailedRoom } from "nvent-web/types/DetailedRoom";
import { Product } from "nvent-web/types/Product";

import { HelpIcon } from "../../components/HelpIcon";
import { getAncestryLevelsString } from "../SelectionGuide/utils/getAncestryString";

import AccessoryItem from "./components/AccessoryItem";
import AddAccessoryForm from "./components/AddAccessoryForm";
import AddProductForm from "./components/AddProductForm";
import AddThermostatForm from "./components/AddThermostatForm";
import { CableSpacingWarningModal } from "./components/CableSpacingWarningModal/CableSpacingWarningModal";
import { PhotoExampleModal } from "./components/PhotoExampleModal";
import PhotoGrid from "./components/PhotoGrid/PhotoGrid";
import ProductItem from "./components/ProductItem";
import { RoomCommissionActions } from "./components/RoomCommissionActions";
import ThermostatItem from "./components/ThermostatItem";
import style from "./RoomDetails.module.scss";
import { BundledAccessory, BundledThermostat } from "./types";

interface RoomDetailsParams {
  projectId: string;
  roomId: string;
}

interface ProjectsProps extends RouteComponentProps<RoomDetailsParams> {
  rooms: RoomsStore;
  projects: ProjectsStore;
  photos: PhotosStore;
  products: ProductsStore;
  notifications: NotificationsStore;
  history: History;
  api: Api;
}

interface RoomDetailsState {
  isAddProductOpen: boolean;
  isAddThermostatOpen: boolean;
  isAddAccessoryOpen: boolean;
  isCableSpacingWarningModalOpen: boolean;
  isRemoveAllProductsOpen: boolean;
  isInstallationPhotoExampleModalOpen: boolean;
  isStickerPhotoExampleModalOpen: boolean;
  isPollingActive: boolean;
  canBeCommissioned: boolean;
}

const MIN_CABLE_SPACING = 0.05;
const MAX_CABLE_SPACING = 0.25;
const MIN_POWER_PER_M2 = 50;

export class RoomDetails extends Component<ProjectsProps, RoomDetailsState> {
  state = {
    isAddProductOpen: false,
    isAddThermostatOpen: false,
    isAddAccessoryOpen: false,
    isCableSpacingWarningModalOpen: false,
    isRemoveAllProductsOpen: false,
    isInstallationPhotoExampleModalOpen: false,
    isStickerPhotoExampleModalOpen: false,
    isPollingActive: false,
    canBeCommissioned: false,
  };

  intervalId: NodeJS.Timeout | null = null;

  @computed
  get isLoading() {
    return (
      this.props.rooms.areRoomDetailsLoading ||
      this.props.projects.isProjectLoading ||
      this.props.products.isProductCatalogLoading
    );
  }

  @computed
  get projectDetails() {
    return this.props.projects.projectDetails;
  }

  @computed
  get roomDetails() {
    return this.props.rooms.roomDetails;
  }

  @computed
  get products() {
    return this.roomDetails ? this.roomDetails.products : [];
  }

  @computed
  get thermostats(): BundledThermostat[] {
    const skus = this.props.products.productSkus;
    const skusById = keyBy(skus, "id");

    const explicit = this.roomDetails ? this.roomDetails.thermostats : [];
    const bundled = this.products
      .map((product, index): BundledThermostat | null => {
        const { thermostatId: id } = product;

        if (id === null || id === undefined || !skusById[id]) {
          return null;
        }

        const { identifier, description } = skusById[id];

        // HACK: shove an SKU into the Thermostat interface...
        return {
          identifier,
          description,
          id: -(index + 1), // HACK: avoid potential conflicts with explicit ids
          productCategoryId: 0,
          source: product,
        };
      })
      .filter((thermostat): thermostat is BundledThermostat => thermostat !== null);

    return [...bundled, ...explicit];
  }

  @computed
  get accessories(): BundledAccessory[] {
    return this.roomDetails ? this.roomDetails.accessories : [];
  }

  @computed
  get hasAnyProduct() {
    return this.products.length > 0 || this.thermostats.length > 0 || this.accessories.length > 0;
  }

  private get params() {
    const rawParams = this.props.match.params;

    return {
      ...rawParams,
      projectId: Number(rawParams.projectId),
      roomId: Number(rawParams.roomId),
    };
  }

  componentDidMount() {
    const { projects, rooms } = this.props;
    const { projectId, roomId } = this.params;

    projects.getProject(projectId);
    rooms.getRoom(projectId, roomId);
  }

  renderEditButton(projectId: number, roomId: number) {
    return (
      <Link to={`/projects/${projectId}/rooms/${roomId}/edit`}>
        <FormattedMessage id="projects.details.edit" />
      </Link>
    );
  }

  renderInstallableArea(installableArea: number, area: number) {
    return (
      <span className={style.area}>
        {installableArea ? `${installableArea} m²` : "-"} / {area ? `${area} m²` : "-"}
      </span>
    );
  }

  renderCableSpacing = (cableSpacing: number) => {
    const isCableSpacingInvalid = cableSpacing < MIN_CABLE_SPACING || cableSpacing > MAX_CABLE_SPACING;
    const cableSpacingElement = <FormattedMeasurement value={cableSpacing * 1000} fractionDigits={0} unit="mm" />;

    return isCableSpacingInvalid ? (
      <Tooltip
        direction="left"
        content={<FormattedMessage id="roomCard.details.invalidCableSpacing" />}
        className={style.tooltip}
        tipContentClassName={style.tipContent}
      >
        <span className={style.invalidValue}>{cableSpacingElement}</span>
        <span className={style.tooltipQuestionMark}>?</span>
      </Tooltip>
    ) : (
      cableSpacingElement
    );
  };

  renderPowerPerM2 = (powerPerM2: number) => {
    const isPowerTooLow = powerPerM2 < MIN_POWER_PER_M2;
    const powerPerM2Element = <FormattedMeasurement value={powerPerM2} fractionDigits={0} unit="W" />;

    return isPowerTooLow ? (
      <Tooltip
        direction="left"
        content={<FormattedMessage id="roomCard.details.invalidPowerPerM2" />}
        className={style.tooltip}
        tipContentClassName={style.tipContent}
      >
        <span className={style.invalidValue}>{powerPerM2Element}</span>
        <span className={style.tooltipQuestionMark}>?</span>
      </Tooltip>
    ) : (
      powerPerM2Element
    );
  };

  renderDetails({
    roomDetails,
    projectDetails,
    isRoomFinished,
  }: {
    roomDetails: DetailedRoom;
    projectDetails: DetailedProject;
    isRoomFinished: boolean;
  }) {
    const { id, name, progress, installableArea, area, floorFinish, cableSpacing, powerPerM2, totalOutput, levels } =
      roomDetails;
    const { floorFinishOptions } = this.props.rooms;

    const floorFinishOption = floorFinishOptions.find((option) => option.id === floorFinish);

    const output = totalOutput;
    const levelsString = levels && levels.length > 0 ? ` / ${getAncestryLevelsString(levels)}` : undefined;
    return (
      <>
        <div className={style.row}>
          <p className={style.breadcrumbs}>
            <Link to={`/projects/${projectDetails.id}`}>{projectDetails.name}</Link>
            {levelsString}
          </p>
          {!isRoomFinished && (
            <span className={style.editButtonMobileWrapper}>{this.renderEditButton(projectDetails.id, id)}</span>
          )}
        </div>
        <div className={style.row}>
          <p className={style.name}>{name}</p>
          <span className={style.areaMobileWrapper}>{this.renderInstallableArea(installableArea, area)}</span>
          {!isRoomFinished && (
            <span className={style.editButtonDesktopWrapper}>{this.renderEditButton(projectDetails.id, id)}</span>
          )}
        </div>
        <span className={style.areaDesktopWrapper}>{this.renderInstallableArea(installableArea, area)}</span>
        <div className={classNames(style.row, style.floorFinishRow)}>
          <div className={style.detailsWrapper}>
            <p className={style.detailsLabel}>
              <FormattedMessage id="rooms.details.floorFinish" />
            </p>
            <span className={style.material}>
              {floorFinishOption ? <TranslatedString value={floorFinishOption.name} /> : "-"}
            </span>
          </div>

          <div className={style.detailsWrapper}>
            <p className={style.detailsLabel}>
              <FormattedMessage id="rooms.details.outputPerSquareMeter" />
            </p>
            <span className={style.material}>
              {powerPerM2 ? this.renderPowerPerM2(powerPerM2) : this.renderToolTip("up-end", "outputPerSquareMeter")}
            </span>
          </div>

          <div className={style.detailsWrapper}>
            <p className={style.detailsLabel}>
              <FormattedMessage id="rooms.details.output" />
            </p>
            <span className={style.material}>
              {output ? <FormattedMeasurement value={output} fractionDigits={2} unit="W" /> : "-"}
            </span>
          </div>

          <div className={style.detailsWrapper}>
            <p className={style.detailsLabel}>
              <FormattedMessage id="rooms.details.cableSpacing" />
            </p>
            <span className={style.material}>
              {cableSpacing ? this.renderCableSpacing(cableSpacing) : this.renderToolTip("down-end", "cableSpacing")}
            </span>
          </div>
        </div>

        {!projectDetails.finished && (
          <div className={classNames(style.row, style.progressRow)}>
            <ProgressFull progress={progress} />
          </div>
        )}
      </>
    );
  }

  render() {
    const { areInstallationPhotosCreating, areStickerPhotosCreating } = this.props.photos;
    const {
      isAddProductOpen,
      isAddThermostatOpen,
      isRemoveAllProductsOpen,
      isAddAccessoryOpen,
      isCableSpacingWarningModalOpen,
      isInstallationPhotoExampleModalOpen,
      isStickerPhotoExampleModalOpen,
    } = this.state;

    const { isLoading, projectDetails, roomDetails, hasAnyProduct, products, thermostats, accessories } = this;

    const isRoomFinished = Boolean(roomDetails?.finishedAt);

    if (isLoading && !roomDetails) {
      return <LoadingSection />;
    }

    return (
      <>
        <PageTitle>
          <FormattedMessage id="rooms.details.title" />
        </PageTitle>
        {roomDetails && projectDetails && (
          <>
            <div className={style.wrapper}>
              {projectDetails && (
                <div className={style.inner}>{this.renderDetails({ roomDetails, projectDetails, isRoomFinished })}</div>
              )}
            </div>
            <div className={style.actions}>
              <RoomCommissionActions
                isFinished={isRoomFinished}
                canBeCommissioned={roomDetails?.progress === 1}
                projectId={projectDetails.id}
                roomId={roomDetails.id}
                roomsStore={this.props.rooms}
                api={this.props.api.rooms}
                notifications={this.props.notifications}
              />
            </div>

            {hasAnyProduct && !isRoomFinished && (
              <SecondaryRedButton className={style.deleteAllButton} onClick={this.openRemoveAllProductsModal}>
                <FormattedMessage id="actions.removeAll" />
              </SecondaryRedButton>
            )}
            <ItemList
              title={<FormattedMessage id="rooms.details.productsList" />}
              onAdd={this.addProduct}
              disableAdd={isRoomFinished}
              required
            >
              {products.length > 0 && this.productsList(products, isRoomFinished)}
            </ItemList>
            <ItemList
              title={<FormattedMessage id="rooms.details.thermostatList" />}
              onAdd={this.addThermostat}
              disableAdd={isRoomFinished}
            >
              {thermostats.length > 0 && this.thermostatsList(thermostats, isRoomFinished)}
            </ItemList>
            <ItemList
              title={<FormattedMessage id="rooms.details.accessoriesList" />}
              onAdd={this.addAccessory}
              disableAdd={isRoomFinished}
            >
              {accessories.length > 0 && this.accessoriesList(accessories, isRoomFinished)}
            </ItemList>
            <div className={style.productListHeader}>
              <div>
                <FormattedMessage id="rooms.details.installationPhotos" />
                <span>*</span>
                <HelpIcon onClick={this.openInstallationPhotoExampleModal} />
              </div>
              {!isRoomFinished && (
                <FileButton
                  multiple={true}
                  disabled={areInstallationPhotosCreating}
                  loading={areInstallationPhotosCreating}
                  theme="secondaryBlue"
                  onChange={this.handleAddInstallationPhotos}
                  className={style.fileButton}
                >
                  <FormattedMessage id="actions.add" />
                </FileButton>
              )}
            </div>
            <div className={style.listWrapper}>
              <PhotoGrid
                removeImage={this.handleRemoveInstallationImage}
                photos={roomDetails.installationPhotos || []}
                deleteDisabled={isRoomFinished}
              />
            </div>
            <div className={style.productListHeader}>
              <div>
                <FormattedMessage id="rooms.details.stickerPhotos" />
                <HelpIcon onClick={this.openStickerPhotoExampleModal} />
              </div>
              {!isRoomFinished && (
                <FileButton
                  multiple={true}
                  disabled={areStickerPhotosCreating}
                  loading={areStickerPhotosCreating}
                  theme="secondaryBlue"
                  onChange={this.handleAddStickerPhotos}
                  className={style.fileButton}
                >
                  <FormattedMessage id="actions.add" />
                </FileButton>
              )}
            </div>
            <div className={style.listWrapper}>
              <PhotoGrid
                removeImage={this.handleRemoveStickerImage}
                photos={roomDetails.stickerPhotos || []}
                deleteDisabled={isRoomFinished}
              />
            </div>
            <Modal center isOpen={isAddProductOpen} handleClose={this.handleAddProductClose}>
              <AddProductForm onClose={this.handleAddProductClose} onSuccess={this.checkIfSufficientCableSpacing} />
            </Modal>
            <Modal center isOpen={isAddThermostatOpen} handleClose={this.handleAddThermostatClose}>
              <AddThermostatForm onClose={this.handleAddThermostatClose} />
            </Modal>
            <Modal center isOpen={isAddAccessoryOpen} handleClose={this.handleAddThermostatClose}>
              <AddAccessoryForm onClose={this.handleAddAccessoryClose} />
            </Modal>
            <ConfirmationModal
              isOpen={isRemoveAllProductsOpen}
              handleClose={this.closeRemoveAllProductsModal}
              center
              title={<FormattedMessage id={"products.removeAllModal.title"} />}
              description={<FormattedMessage id={"products.removeAllModal.description"} />}
              onCancel={this.closeRemoveAllProductsModal}
              onConfirm={this.deleteAllProducts}
            />
            <Modal center isOpen={isCableSpacingWarningModalOpen} handleClose={this.closeCableSpacingWarningModal}>
              <CableSpacingWarningModal onClose={this.closeCableSpacingWarningModal} />
            </Modal>

            <PhotoExampleModal
              titleTextMessageId={"rooms.details.installationPhotos.exampleModal.title"}
              descriptionTextMessageId={"rooms.details.installationPhotos.exampleModal.description"}
              photoAltTextMessageId={"rooms.details.installationPhotos.exampleModal.examplePhotoAlt"}
              photoSrc={InstallationPhotoExampleSrc}
              onClose={this.closeInstallationPhotoExampleModal}
              isOpen={isInstallationPhotoExampleModalOpen}
            />
            <PhotoExampleModal
              titleTextMessageId={"rooms.details.stickerPhotos.exampleModal.title"}
              descriptionTextMessageId={"rooms.details.stickerPhotos.exampleModal.description"}
              photoAltTextMessageId={"rooms.details.stickerPhotos.exampleModal.examplePhotoAlt"}
              photoSrc={StickerPhotoExampleSrc}
              onClose={this.closeStickerPhotoExampleModal}
              isOpen={isStickerPhotoExampleModalOpen}
            />
          </>
        )}
      </>
    );
  }

  renderTabs() {
    return (
      <>
        <div className={style.tabs} />
      </>
    );
  }

  private getImageParams = () => {
    const { match } = this.props;
    const projectId = Number(match.params.projectId);
    const roomId = Number(match.params.roomId);

    return {
      projectId,
      roomId,
    };
  };

  private getValidPhotos = (photos: ValidatedFile[]) => {
    return photos.filter(({ isValid }) => isValid);
  };

  private getInvalidPhotos = (photos: ValidatedFile[]) => {
    return photos.filter(({ isValid }) => !isValid);
  };

  private createInvalidPhotosErrors = (invalidPhotos: ValidatedFile[]) => {
    invalidPhotos.forEach((photo) => {
      if (photo.errors.type) {
        this.props.notifications.createError(
          <>
            {photo.file.name} - <FormattedMessage id="error.image.type" />
          </>
        );
      } else if (photo.errors.size) {
        this.props.notifications.createError(
          <>
            {photo.file.name} -{" "}
            <FormattedMessage id="error.image.maxSize" values={{ maxSize: prettyBytes(defaultMaxSize) }} />
          </>
        );
      }
    });
  };

  private handleRemoveInstallationImage = (imageId: number) => {
    const { projectId, roomId } = this.getImageParams();

    this.props.photos.deleteInstallationPhoto(projectId, roomId, imageId);
  };

  private handleRemoveStickerImage = (imageId: number) => {
    const { projectId, roomId } = this.getImageParams();

    this.props.photos.deleteStickerPhoto(projectId, roomId, imageId);
  };

  private handleAddInstallationPhotos = async (photos: ValidatedFile[]) => {
    const validPhotos = this.getValidPhotos(photos);
    const invalidPhotos = this.getInvalidPhotos(photos);

    this.createInvalidPhotosErrors(invalidPhotos);

    if (validPhotos.length) {
      const { projectId, roomId } = this.getImageParams();

      await this.props.photos.createInstallationPhotos(
        projectId,
        roomId,
        validPhotos.map(({ file }) => file)
      );
    }
  };

  private handleAddStickerPhotos = async (photos: ValidatedFile[]) => {
    const validPhotos = this.getValidPhotos(photos);
    const invalidPhotos = this.getInvalidPhotos(photos);

    this.createInvalidPhotosErrors(invalidPhotos);

    if (validPhotos.length) {
      const { projectId, roomId } = this.getImageParams();

      await this.props.photos.createStickerPhotos(
        projectId,
        roomId,
        validPhotos.map(({ file }) => file)
      );
    }
  };

  private handleAddProductClose = () => {
    this.setState({ isAddProductOpen: false });
  };

  private checkIfSufficientCableSpacing = () => {
    const { roomDetails } = this.props.rooms;
    if (roomDetails && roomDetails.cableSpacing && roomDetails.cableSpacing < 0.05) {
      this.openCableSpacingWarningModal();
    }
  };

  private addProduct = () => {
    this.setState({ isAddProductOpen: true });
  };

  private openCableSpacingWarningModal = () => {
    this.setState({ isCableSpacingWarningModalOpen: true });
  };

  private openInstallationPhotoExampleModal = () => {
    this.setState({ isInstallationPhotoExampleModalOpen: true });
  };

  private openStickerPhotoExampleModal = () => {
    this.setState({ isStickerPhotoExampleModalOpen: true });
  };
  private closeCableSpacingWarningModal = () => {
    this.setState({ isCableSpacingWarningModalOpen: false });
  };

  private closeInstallationPhotoExampleModal = () => {
    this.setState({ isInstallationPhotoExampleModalOpen: false });
  };

  private closeStickerPhotoExampleModal = () => {
    this.setState({ isStickerPhotoExampleModalOpen: false });
  };

  private handleAddThermostatClose = () => {
    this.setState({ isAddThermostatOpen: false });
  };

  private handleAddAccessoryClose = () => {
    this.setState({ isAddAccessoryOpen: false });
  };

  private addThermostat = () => {
    this.setState({ isAddThermostatOpen: true });
  };

  private addAccessory = () => {
    this.setState({ isAddAccessoryOpen: true });
  };

  private closeRemoveAllProductsModal = () => {
    this.setState({ isRemoveAllProductsOpen: false });
  };

  private openRemoveAllProductsModal = () => {
    this.setState({ isRemoveAllProductsOpen: true });
  };

  private deleteAllProducts = async () => {
    const { match, products } = this.props;
    const projectId = Number(match.params.projectId);
    const roomId = Number(match.params.roomId);
    await products.removeAllProducts(projectId, roomId);
    this.closeRemoveAllProductsModal();
  };

  private productsList(products: Product[], roomFinished: boolean) {
    return products.map((item) => (
      <ProductItem
        key={item.id}
        product={item}
        onRemove={this.removeProductItem}
        onTest={this.onTestClick}
        disableActions={roomFinished}
      />
    ));
  }

  private removeProductItem = async (id: number) => {
    const { products } = this.props;
    const { projectId, roomId } = this.params;
    await products.removeProduct(projectId, roomId, id);
  };

  private thermostatsList(thermostats: BundledThermostat[], projectFinished: boolean) {
    return thermostats.map((item) => (
      <ThermostatItem
        key={item.id}
        thermostat={item}
        onRemove={this.removeThermostatItem}
        disableActions={!!item.source || projectFinished}
      />
    ));
  }

  private accessoriesList(accessories: BundledAccessory[], projectFinished: boolean) {
    return Object.values(groupBy(accessories, (item) => item.accessoryId)).map((items) => {
      const item = items[0];

      return (
        <AccessoryItem
          key={item.id}
          accessory={item}
          onRemove={this.removeAccessoryItem}
          disableActions={!!item.source || projectFinished}
          quantity={items.length}
        />
      );
    });
  }

  private removeThermostatItem = async (id: number) => {
    const { products } = this.props;
    const { projectId, roomId } = this.params;
    await products.removeThermostat(projectId, roomId, id);
  };

  private removeAccessoryItem = async (id: number) => {
    const { products } = this.props;
    const { projectId, roomId } = this.params;
    await products.removeAccessory(projectId, roomId, id);
  };

  private onTestClick = (productId: number) => {
    const {
      match: {
        params: { projectId, roomId },
      },
    } = this.props;
    this.props.history.push(`/projects/${projectId}/rooms/${roomId}/room_products/${productId}/tests`);
  };

  private renderToolTip(direction: string, label: string) {
    return (
      <HelpTip direction={direction}>
        <FormattedMessage id={`rooms.details.${label}.tooltip`} />
      </HelpTip>
    );
  }
}

export default compose<ProjectsProps, Record<string, unknown>>(
  withRouter,
  inject("rooms", "projects", "photos", "products", "notifications", "api"),
  observer
)(RoomDetails);
