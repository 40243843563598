import classNames from "classnames";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { ReactComponent as EditIcon } from "nvent-web/assets/svg/edit.svg";
import { ReactComponent as OpenIcon } from "nvent-web/assets/svg/open.svg";
import { ReactComponent as ProductIcon } from "nvent-web/assets/svg/product.svg";
import { ReactComponent as RoomIcon } from "nvent-web/assets/svg/room.svg";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import { Room } from "nvent-web/types/Room";

import { CommissionedLabel } from "../CommissionedLabel";
import { Tooltip } from "../Tooltip";

import style from "./RoomItemCard.module.scss";

type RoomItemCardCommonProps = {
  data: Room;
  index?: number;
  levelId?: number;
  children?: ReactNode;
  isProjectFinished: boolean;
  nestLevelIndex: number;
  link: string;
};

type RoomItemCardProps =
  | (RoomItemCardCommonProps & {
      onEdit: () => void;

      isReadOnly?: false;
    })
  | (RoomItemCardCommonProps & {
      isReadOnly: true;
    });

export const RoomItemCard = (props: RoomItemCardProps) => {
  const {
    data: { name, progress, finishedAt, products },
    index = 0,
    levelId,
    nestLevelIndex,
    isProjectFinished,
    isReadOnly,
    children,
    link,
  } = props;

  const isRoomCommissioned = Boolean(finishedAt);

  const hasProducts = products && products.length > 0;

  return (
    <li
      className={classNames(
        style.wrapper,
        { [style.nestedRoom]: Boolean(levelId) },
        nestLevelIndex % 2 !== 0 && style.dark
      )}
    >
      {!isProjectFinished && (
        <ProgressFull
          progress={progress}
          borderRadius={index === 0 ? "top" : "none"}
          className={{ wrapper: style.progress }}
          isDisabled={isRoomCommissioned}
        />
      )}
      <div className={style.content}>
        <div className={style.header}>
          <div className={style.info}>
            <RoomIcon className={style.roomIcon} />
            <div className={style.titleWrapper}>
              <Link to={link} className={classNames(style.title, isRoomCommissioned && style.disabled)}>
                {name}
              </Link>
              {!isReadOnly && !isRoomCommissioned && (
                <Tooltip content={<FormattedMessage id="card.tooltip.edit" />}>
                  <button className={style.editButton} onClick={props.onEdit}>
                    <EditIcon />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={style.contextMenus}>
            {isRoomCommissioned && !isProjectFinished && <CommissionedLabel />}
            {hasProducts && <ProductIcon />}
            <Tooltip content={<FormattedMessage id="card.tooltip.open" />}>
              <Link to={link} className={classNames(style.open, style.link)}>
                <OpenIcon />
              </Link>
            </Tooltip>
            {children}
          </div>
        </div>
      </div>
    </li>
  );
};
