import { inject } from "mobx-react";
import { useState } from "react";
import MediaQuery from "react-responsive";
import { compose } from "recompose";

import { AddLevelOrRoomModal } from "nvent-web/components/AddLevelOrRoomModal/AddLevelOrRoomModal";
import { useGenerateReport } from "nvent-web/hooks/useGenerateReport";
import { useGetBOM } from "nvent-web/hooks/useGetBOM";
import { gridBreakpoints } from "nvent-web/lib/constants";
import Api from "nvent-web/services/Api";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { STOP_POLLING_STATUSES } from "nvent-web/utils/polling";

import { DesktopProjectDetailsCard } from "../DesktopProjectDetailsCard";
import { MobileProjectDetailsCard } from "../MobileProjectDetailsCard";

type ProjectDetailsCardInnerProps = {
  api: Api;
  notifications: NotificationsStore;
};

type ProjectDetailsCardOuterProps = {
  project: DetailedProject;
  isAddLevelLoading: boolean;
  onEdit: (id: number) => void;
  onAddLevel: (id: number) => void;
};

type ProjectDetailsCardProps = ProjectDetailsCardInnerProps & ProjectDetailsCardOuterProps;

const ProjectDetailsCardInner = (props: ProjectDetailsCardProps) => {
  const { onAddLevel, isAddLevelLoading, api, notifications, ...cardProps } = props;
  const { id } = props.project;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const { generateReport: generateCommissionReport, isGenerateReportSubmitting: isGenerateCommissionReportSubmitting } =
    useGenerateReport({
      id,
      notificationsStore: notifications,
      fetchReportStatusCallback: api.projects.fetchCommissionReportStatus.bind(api.projects),
      generateReportCallback: api.projects.generateCommissionReport.bind(api.projects),
      downloadReportCallback: api.projects.downloadCommissionReportV3.bind(api.projects),
      isBlobResponse: true,
      stopPollingStatuses: STOP_POLLING_STATUSES,
      pollingInterval: 5000,
    });

  const { generateReport: generateQuickReport, isGenerateReportSubmitting: isGenerateQuickReportSubmitting } =
    useGenerateReport({
      id,
      notificationsStore: notifications,
      fetchReportStatusCallback: api.projects.fetchQuickReportStatus.bind(api.projects),
      generateReportCallback: api.projects.generateQuickReport.bind(api.projects),
      isBlobResponse: false,
      stopPollingStatuses: STOP_POLLING_STATUSES,
      pollingInterval: 2000,
    });

  const isFinished = props.project.finished;

  const { getBOM, isGetBOMSubmitting } = useGetBOM({
    id,
    getBOMCallback: api.projects.downloadBillOfMaterialsV2.bind(api.levels),
    notifications,
  });

  const commonProps = {
    ...cardProps,
    reportDownloading: isFinished ? isGenerateCommissionReportSubmitting : isGenerateQuickReportSubmitting,
    billOfMaterialsDownloading: isGetBOMSubmitting,
    onAddLevel: () => setIsAddModalOpen(true),
    onGetBOM: getBOM,
    onGetReport: isFinished ? generateCommissionReport : generateQuickReport,
  };

  return (
    <>
      <MediaQuery minWidth={gridBreakpoints.lg}>
        {(isDesktop) => {
          if (isDesktop) {
            return <DesktopProjectDetailsCard {...commonProps} />;
          } else {
            return <MobileProjectDetailsCard {...commonProps} />;
          }
        }}
      </MediaQuery>
      <AddLevelOrRoomModal
        isOpen={isAddModalOpen}
        isAddLevelLoading={isAddLevelLoading}
        onClose={() => setIsAddModalOpen(false)}
        onAddLevel={() => onAddLevel(props.project.id)}
        projectId={props.project.id}
      />
    </>
  );
};

export const ProjectDetailsCard = compose<ProjectDetailsCardProps, ProjectDetailsCardOuterProps>(
  inject("api", "notifications")
)(ProjectDetailsCardInner);
