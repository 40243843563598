import { FunctionComponent } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

import style from "./StepHeading.module.scss";

interface StepHeadingProps {
  step: number;
  ancestry?: string;
}

const StepHeading: FunctionComponent<StepHeadingProps> = ({ step, ancestry }) => {
  return (
    <>
      <h1 className={style.title}>
        <FormattedHTMLMessage id="selectionGuide.step.title" values={{ step }} />
      </h1>
      {ancestry && <h2>{ancestry}</h2>}
      <p className={style.description}>
        <FormattedMessage id={`selectionGuide.step${step}.description`} />
      </p>
    </>
  );
};

export default StepHeading;
