import { isAxiosError } from "axios";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import { PrimaryBlueButton, PrimaryGreenButton } from "nvent-web/components/Button";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import Modal from "nvent-web/components/Modal";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { MoveLevelFinishedRoomsForm } from "nvent-web/components/MoveLevelFinishedRoomsForm";
import Api from "nvent-web/services/Api";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { CommissioningFormValues } from "nvent-web/types/CommissioningFormValues";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { DetailedRoom } from "nvent-web/types/DetailedRoom";
import { Level } from "nvent-web/types/Level";

import CommissioningForm from "./components/CommissioningForm";
import { LevelItem } from "./components/LevelItem";
import { ProjectDetailsCard } from "./components/ProjectDetailsCard";
import { RoomItem } from "./components/RoomItem";
import style from "./ProjectDetails.module.scss";

interface ProjectDetailsParams {
  projectId: string;
}

interface ProjectsProps extends RouteComponentProps<ProjectDetailsParams>, InjectedIntlProps {
  projects: ProjectsStore;
  rooms: RoomsStore;
  api: Api;
  notifications: NotificationsStore;
}

interface ProjectsState {
  isCommissioningModalOpen: boolean;
  isCommissioningSuccessModalOpen: boolean;
  isSendReportModalOpen: boolean;
  isCommissionProjectSubmitting: boolean;
  commissioningValues: CommissioningFormValues | undefined;
  isAddLevelSubmitting: boolean;
  isCopyLevelSubmitting: boolean;
  isEditNameSubmitting: boolean;
  isRemoveLevelSubmitting: boolean;
  isCopyRoomSubmitting: boolean;
  isCommissionRoomSubmitting: boolean;
  isGenerateReportSubmitting: boolean;
  isCommissionLevelSubmitting: boolean;
  levelBeingDeletedId?: number;
}

export const COMPLETED_PROGRESS = 1;

export class ProjectDetails extends Component<ProjectsProps, ProjectsState> {
  state: ProjectsState = {
    isCommissioningModalOpen: false,
    isCommissioningSuccessModalOpen: false,
    commissioningValues: undefined,
    isCommissionProjectSubmitting: false,
    isSendReportModalOpen: false,
    isAddLevelSubmitting: false,
    isEditNameSubmitting: false,
    isCopyLevelSubmitting: false,
    isRemoveLevelSubmitting: false,
    isCopyRoomSubmitting: false,
    isCommissionRoomSubmitting: false,
    isGenerateReportSubmitting: false,
    isCommissionLevelSubmitting: false,
    levelBeingDeletedId: undefined,
  };

  componentDidMount() {
    this.props.projects.getProject(Number(this.props.match.params.projectId));
  }

  render() {
    const { projectDetails, areProjectDetailsLoading } = this.props.projects;
    const {
      isCommissioningModalOpen,
      isCommissioningSuccessModalOpen,
      commissioningValues,
      isSendReportModalOpen,
      levelBeingDeletedId,
    } = this.state;

    if (areProjectDetailsLoading) {
      return <LoadingSection />;
    }

    return (
      <>
        <PageTitle>
          <FormattedMessage id="projects.details.title" />
        </PageTitle>
        {projectDetails && (
          <>
            <div className={style.wrapper}>
              <div className={style.inner}>
                <ProjectDetailsCard
                  project={projectDetails}
                  isAddLevelLoading={this.state.isAddLevelSubmitting}
                  onEdit={this.editProjectItem}
                  onAddLevel={() =>
                    this.addLevel({ projectId: projectDetails.id, projectAddress: projectDetails.address })
                  }
                />
              </div>
            </div>
            <div className={style.commissioning}>
              {projectDetails.progress === COMPLETED_PROGRESS && (
                <>
                  {!projectDetails.finished && (
                    <h3 className={style.ready}>
                      <FormattedMessage id="commissioning.ready" />
                    </h3>
                  )}
                  {projectDetails.finished ? (
                    <PrimaryBlueButton className={style.button} onClick={this.handleSendReportModalOpen}>
                      <FormattedMessage id="send.report" />
                    </PrimaryBlueButton>
                  ) : (
                    <PrimaryGreenButton className={style.button} onClick={this.handleCommissioning}>
                      <FormattedMessage id="actions.commissioning" />
                    </PrimaryGreenButton>
                  )}
                </>
              )}
            </div>

            {this.renderList({
              rooms: projectDetails.rooms,
              levels: projectDetails.levels,
              isFinished: projectDetails.finished,
            })}
          </>
        )}
        <ConfirmationModal
          center
          isOpen={isCommissioningModalOpen}
          handleClose={this.handleCommissioningModalClose}
          title={<FormattedMessage id="projectCommissioningConfirmationModal.title" />}
          description={<FormattedMessage id="projectCommissioningConfirmationModal.description" />}
          onCancel={this.handleCommissioningModalClose}
          loading={this.state.isCommissionProjectSubmitting}
          onConfirm={this.handleCommissionProject}
        />
        <Modal center isOpen={isSendReportModalOpen} handleClose={this.handleSendReportModalClose}>
          <CommissioningForm onSubmit={this.onSendReportEmailSuccess} onClose={this.handleSendReportModalClose} />
        </Modal>

        <Modal center isOpen={isCommissioningSuccessModalOpen} handleClose={this.handleCommissioningSuccessModalClose}>
          {commissioningValues && this.renderSuccessModalInner(commissioningValues)}
        </Modal>
        <Modal center isOpen={Boolean(levelBeingDeletedId)} handleClose={this.handleRoomMovingOutsideClick}>
          {projectDetails && projectDetails.id && levelBeingDeletedId && (
            <MoveLevelFinishedRoomsForm
              projectId={projectDetails.id}
              projectLevels={projectDetails.levels}
              levelId={levelBeingDeletedId}
              onCancel={this.handleRoomMovingOutsideClick}
              onSave={(newLevelId) => this.deleteLevelWithRoomTransfer(newLevelId)}
            />
          )}
        </Modal>
      </>
    );
  }

  private renderList({ rooms, levels, isFinished }: { rooms: DetailedRoom[]; levels: Level[]; isFinished: boolean }) {
    if (rooms.length === 0 && levels.length === 0) {
      return (
        <h2 className={style.noContent}>
          <FormattedMessage id="projects.messages.noRoomsOrLevels" />
        </h2>
      );
    }

    const projectId = Number(this.props.match.params.projectId);
    const {
      isAddLevelSubmitting,
      isEditNameSubmitting,
      isRemoveLevelSubmitting,
      isCopyLevelSubmitting,
      isCopyRoomSubmitting,
      isCommissionLevelSubmitting,
      isCommissionRoomSubmitting,
    } = this.state;

    return (
      <ul className={style.listWrapper}>
        {levels.map((level) => (
          <LevelItem
            key={level.id}
            level={level}
            projectId={projectId}
            nestLevelIndex={0}
            isProjectFinished={isFinished}
            isAddLevelSubmitting={isAddLevelSubmitting}
            isEditNameSubmitting={isEditNameSubmitting}
            isRemoveLevelSubmitting={isRemoveLevelSubmitting}
            isCopyLevelSubmitting={isCopyLevelSubmitting}
            isCopyRoomSubmitting={isCopyRoomSubmitting}
            isCommissionLevelSubmitting={isCommissionLevelSubmitting}
            isCommissionRoomSubmitting={isCommissionRoomSubmitting}
            onAddLevel={(levelId) => {
              this.addLevel({ projectId, projectAddress: level.address, parentLevelId: levelId });
            }}
            onEditLevelName={this.editLevelName}
            onCopyLevel={this.copyLevel}
            onRemoveLevel={this.removeLevel}
            onRemoveRoom={this.removeRoomItem}
            onEditRoom={this.editRoomItem}
            onCopyRoom={this.copyRoomItem}
            onCommissionRoom={this.commissionRoomItem}
            onCommissionLevel={this.commissionLevelItem}
          />
        ))}

        {rooms.map((item) => (
          <RoomItem
            key={item.id}
            projectId={projectId}
            data={item}
            nestLevelIndex={0}
            isCopyRoomSubmitting={isCopyRoomSubmitting}
            isCommissionSubmitting={isCommissionRoomSubmitting}
            onRemove={this.removeRoomItem}
            onEdit={this.editRoomItem}
            isProjectFinished={isFinished}
            onCopy={this.copyRoomItem}
            onCommission={this.commissionRoomItem}
          />
        ))}
      </ul>
    );
  }

  private renderSuccessModalInner(values?: CommissioningFormValues) {
    return (
      <>
        <h3 className={style.title}>
          <FormattedMessage id="commissioning.success" />
        </h3>
        <p className={style.notice}>
          <FormattedMessage id="commissioning.reportSuccessfullySent" />
        </p>
        {values && (
          <>
            <p>{values.installerEmail}</p>
            <p>{values.clientEmail}</p>

            {values.additionalEmails.split(",").map((email) => (
              <p key={email}>{email}</p>
            ))}
          </>
        )}

        <div className={style.actions}>
          <PrimaryBlueButton className={style.okButton} onClick={this.handleCommissioningSuccessModalClose}>
            <FormattedMessage id="actions.ok" />
          </PrimaryBlueButton>
        </div>
      </>
    );
  }

  private onSendReportEmailSuccess = (commissioningValues: CommissioningFormValues) => {
    this.setState({ isSendReportModalOpen: false, isCommissioningSuccessModalOpen: true, commissioningValues });
  };

  private handleCommissionProject = async () => {
    const { projectId } = this.props.match.params;
    try {
      this.setState({ isCommissionProjectSubmitting: true });

      await this.props.api.projects.sendCommission(Number(projectId));
    } catch (err) {
      this.handleError(err, "error.projectCommissioningFailed");
      this.setState({ isCommissionLevelSubmitting: false, isCommissioningModalOpen: false });
      return;
    }

    try {
      this.props.projects.getProject(Number(projectId));
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isCommissionLevelSubmitting: false });
      return;
    }

    this.setState({
      isCommissionProjectSubmitting: false,
      isCommissioningModalOpen: false,
      isSendReportModalOpen: true,
    });
  };

  private handleCommissioningSuccessModalClose = () => {
    this.setState({ isCommissioningSuccessModalOpen: false, commissioningValues: undefined });

    this.props.history.push("/projects/finished");
  };

  private handleCommissioning = () => {
    const { projectDetails } = this.props.projects;

    if (projectDetails && projectDetails.allRoomsHavePhotos) {
      this.setState({ isCommissioningModalOpen: true });
    } else {
      this.props.notifications.createError(<FormattedMessage id="commissioning.installationPhotos.validation" />);
    }
  };

  private handleSendReportModalOpen = () => {
    this.setState({ isSendReportModalOpen: true });
  };

  private handleSendReportModalClose = () => {
    this.setState({ isSendReportModalOpen: false });
  };

  private handleCommissioningModalClose = () => {
    this.setState({ isCommissioningModalOpen: false });
  };

  private handleRoomMovingOutsideClick = () => {
    this.setState({ ...this.state, levelBeingDeletedId: undefined });
  };

  private editProjectItem = (id: number) => {
    this.props.history.push(`/projects/${id}/edit`);
  };

  private removeRoomItem = async (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    await this.props.rooms.removeRoom(projectId, id);
    await this.props.projects.getProject(projectId);
  };

  private editRoomItem = (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    this.props.history.push(`/projects/${projectId}/rooms/${id}/edit`);
  };

  private copyRoomItem = async (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    const { rooms, projects, notifications } = this.props;

    try {
      this.setState({ isCopyRoomSubmitting: true });
      await rooms.copyRoom(projectId, id);
    } catch (err) {
      this.handleError(err, "error.roomCopyFailed");
      this.setState({ isCopyRoomSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isCopyRoomSubmitting: false });
      return;
    }

    notifications.createSuccess(<FormattedMessage id="rooms.messages.copied" />);
    this.setState({ isCopyRoomSubmitting: false });
  };

  private commissionRoomItem = async (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    const { api, notifications, projects } = this.props;
    try {
      this.setState({ isCommissionRoomSubmitting: true });
      await api.rooms.commission(id);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.status === api.rooms.errors.roomHasNoPhotos) {
          notifications.createError(<FormattedMessage id="room.commissioning.installationPhotos.validation" />);
          this.setState({ isCommissionRoomSubmitting: false });
          return;
        }
      }
      this.handleError(err, "error.roomCommissionFailed");
      this.setState({ isCommissionRoomSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isCommissionRoomSubmitting: false });
      return;
    }
    notifications.createSuccess(<FormattedMessage id="rooms.messages.commissioned" />);
    this.setState({ isCommissionRoomSubmitting: false });
  };

  private commissionLevelItem = async (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    const { api, notifications, projects } = this.props;
    try {
      this.setState({ isCommissionLevelSubmitting: true });
      await api.levels.commission(id);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.status === api.levels.errors.levelHasNoPhotos) {
          notifications.createError(<FormattedMessage id="commissioning.installationPhotos.validation" />);
          this.setState({ isCommissionLevelSubmitting: false });
          return;
        }
      }
      this.handleError(err, "error.levelCommissionFailed");
      this.setState({ isCommissionLevelSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isCommissionLevelSubmitting: false });
      return;
    }
    notifications.createSuccess(<FormattedMessage id="level.messages.commissioned" />);
    this.setState({ isCommissionLevelSubmitting: false });
  };

  private addLevel = async ({
    projectId,
    projectAddress,
    parentLevelId,
  }: {
    projectId: DetailedProject["id"];
    projectAddress?: DetailedProject["address"];
    parentLevelId?: number;
  }) => {
    const {
      api: { levels },
      projects,
      notifications,
    } = this.props;

    this.setState({ isAddLevelSubmitting: true });

    try {
      await levels.create({
        data: {
          ...(projectAddress && { address: projectAddress }),
          parentLevelId,
        },
        projectId,
      });
    } catch (err) {
      this.handleError(err, "error.levelCreationFailed");
      this.setState({ isAddLevelSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isAddLevelSubmitting: false });
      return;
    }

    notifications.createSuccess(<FormattedMessage id="level.messages.created" />);
    this.setState({ isAddLevelSubmitting: false });
  };

  private editLevelName = async (levelId: number, name: string) => {
    const {
      api: { levels },
      projects,
      notifications,
    } = this.props;

    const projectId = Number(this.props.match.params.projectId);
    this.setState({ isEditNameSubmitting: true });

    try {
      await levels.edit({ projectId, levelId, data: { name } });
    } catch (err) {
      this.handleError(err, "error.levelEditNameFailed");
      this.setState({ isEditNameSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isEditNameSubmitting: false });
      return;
    }

    notifications.createSuccess(<FormattedMessage id="level.messages.nameEdited" />);
    this.setState({ isEditNameSubmitting: false });
  };

  private copyLevel = async (levelId: number) => {
    const {
      api: { levels },
      projects,
      notifications,
    } = this.props;

    this.setState({ isCopyLevelSubmitting: true });
    const projectId = Number(this.props.match.params.projectId);

    try {
      await levels.copy(levelId);
    } catch (err) {
      this.handleError(err, "error.levelCopyFailed");
      this.setState({ isCopyLevelSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isCopyLevelSubmitting: false });
      return;
    }

    notifications.createSuccess(<FormattedMessage id="level.messages.copied" />);
    this.setState({ isCopyLevelSubmitting: false });
  };

  private removeLevel = async (levelId: number) => {
    const {
      api: { levels },
      projects,
      notifications,
    } = this.props;

    this.setState({ isRemoveLevelSubmitting: true });
    const projectId = Number(this.props.match.params.projectId);

    try {
      await levels.remove({ projectId, levelId });
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        if (err.status === levels.errors.levelHasComissionedElements) {
          this.setState({ ...this.state, isRemoveLevelSubmitting: false, levelBeingDeletedId: levelId });
        } else {
          this.handleError(err, "error.levelRemovalFailed");
        }
      } else {
        this.handleError(err, "error.levelRemovalFailed");
      }
      this.setState({ isRemoveLevelSubmitting: false });
      return;
    }

    try {
      projects.getProject(projectId);
    } catch (err) {
      this.handleError(err, "error.projectDetailsFetchFailed");
      this.setState({ isRemoveLevelSubmitting: false });
      return;
    }

    notifications.createSuccess(<FormattedMessage id="level.messages.removed" />);
    this.setState({ isRemoveLevelSubmitting: false });
  };

  private deleteLevelWithRoomTransfer = async (newLevelId?: number) => {
    const levelBeingDeletedId = this.state.levelBeingDeletedId;
    try {
      if (!levelBeingDeletedId) {
        throw new Error("Attempt to move rooms from undefined level");
      }
      await this.props.api.levels.moveFinishedRooms(levelBeingDeletedId, newLevelId);
      this.setState({ ...this.state, levelBeingDeletedId: undefined });
    } catch (err) {
      this.handleError(err, "error.roomTransferingFailed");
      return;
    }

    await this.removeLevel(levelBeingDeletedId);
  };

  private handleError = (err: unknown, translationId: string) => {
    logger.error(err);
    this.props.notifications.createError(<FormattedMessage id={translationId} />);
  };
}

export default compose<ProjectsProps, Record<string, unknown>>(
  inject("projects", "rooms", "notifications", "api"),
  injectIntl,
  observer
)(ProjectDetails);
