import format from "date-fns/format";
import { MouseEventHandler } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as EditIcon } from "nvent-web/assets/svg/edit.svg";
import { GetBOMAction } from "nvent-web/components/Actions/GetBOMAction";
import { GetPDFAction } from "nvent-web/components/Actions/GetPDFAction";
import { GetQuickReportAction } from "nvent-web/components/Actions/GetQuickReportAction";
import { Button } from "nvent-web/components/Button";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import { Tooltip } from "nvent-web/components/Tooltip";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { formatReferenceNumber } from "nvent-web/utils/formatReferenceNumber";

import style from "./DesktopProjectDetailsCard.module.scss";

type DesktopProjectDetailsCardProps = {
  project: DetailedProject;
  reportDownloading?: boolean;
  billOfMaterialsDownloading?: boolean;
  onGetBOM: (id: number) => void;
  onEdit: (id: number) => void;
  onGetReport: MouseEventHandler<HTMLButtonElement>;
  onAddLevel: () => void;
};

export const DesktopProjectDetailsCard = ({
  project,
  reportDownloading,
  billOfMaterialsDownloading,
  onGetBOM,
  onEdit,
  onGetReport,
  onAddLevel,
}: DesktopProjectDetailsCardProps) => {
  const { createdAt, progress, address, referenceNumber, name, id, clientName, finished } = project;

  const reportProps = {
    className: style.actionButton,
    onClick: onGetReport,
    loading: reportDownloading,
  };

  return (
    <>
      <div className={style.wrapper}>
        {!finished && <ProgressFull progress={progress} borderRadius="none" className={{ wrapper: style.progress }} />}
        <div className={style.content}>
          <div className={style.header}>
            <div className={style.info}>
              <div className={style.titleWrapper}>
                <h4 className={style.title}>{name}</h4>
                <Tooltip content={<FormattedMessage id="card.tooltip.edit" />}>
                  <button className={style.editButton} onClick={() => onEdit(id)}>
                    <EditIcon />
                  </button>
                </Tooltip>
              </div>
              <div className={style.details}>
                <span>{address.streetAddress}</span>
                <span>{clientName}</span>
                <span className={style.referenceNumber}>{formatReferenceNumber(referenceNumber)}</span>
                <span className={style.date}>{format(createdAt, "dd-MM-yyyy")}</span>
              </div>
            </div>
            <div className={style.actionButtonsWrapper}>
              {finished ? <GetPDFAction {...reportProps} /> : <GetQuickReportAction {...reportProps} />}
              <GetBOMAction
                className={style.actionButton}
                onClick={() => onGetBOM(id)}
                loading={billOfMaterialsDownloading}
              />
              {!finished && (
                <Button theme="secondaryBlue" onClick={onAddLevel}>
                  <FormattedMessage id="actions.add" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
